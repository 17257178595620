<template>
  <vue-multiselect :class="['AsomInputSelectMonth']" track-by="label" label="label" v-bind="$attrs" :options="options"
    v-model="computedValue" :searchable="true" :disabled="disabled" ref="multiselect" :placeholder="placeholder">
  </vue-multiselect>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import inputStateMixin from "../inputStateMixin";

export default {
  name: "AsomInputSelectMonth",
  components: {
    VueMultiselect,
  },
  inheritAttrs: false,
  mixins: [inputStateMixin],
  props: {
    yearVal: {
      type: Number,
      default: null,
    },
    modelValue: {
      type: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    objectModelValue: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Select option",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      options: [],
    }
  },
  watch: {
    yearVal() {
      this.getMonth();
    }
  },
  computed: {
    computedValue: {
      get() {
        if (this.objectModelValue) return this.modelValue;
        if (this.options && this.options.find) {
          return this.options.find((option) => option.value == this.modelValue);
        }
        return null;
      },
      set(selectedOption) {
        if (this.objectModelValue) {
          this.$emit("update:modelValue", selectedOption);
          return;
        }
        this.$emit("update:modelValue", selectedOption && selectedOption.value);
      },
    }
  },
  created() {
    this.getMonth();
  },
  methods: {
    getMonth() {
      let months = [
        {
          label: 'January',
          value: '01',
        },
        {
          label: 'February',
          value: '02',
        },
        {
          label: 'March',
          value: '03',
        },
        {
          label: 'April',
          value: '04',
        },
        {
          label: 'May',
          value: '05',
        },
        {
          label: 'June',
          value: '06',
        },
        {
          label: 'July',
          value: '07',
        },
        {
          label: 'August',
          value: '08',
        },
        {
          label: 'September',
          value: '09',
        },
        {
          label: 'October',
          value: '10',
        },
        {
          label: 'November',
          value: '11',
        },
        {
          label: 'December',
          value: '12',
        }
      ];
      const toYear = new Date().getFullYear();
      const toMonth = new Date().getMonth();
      if (this.yearVal == toYear) {
        months = months.slice(0, toMonth);
        if (toMonth > 0) {
          this.computedValue = {
            label: months[toMonth - 1].label,
            value: months[toMonth - 1].value
          };
        }
      }
      this.options = months;
    },
    clearDropdownOptions() {
      if (this.$refs.multiselect && this.$refs.multiselect.refreshOptions)
        this.$refs.multiselect.refreshOptions();
    },
  },
};
</script>
