<template>
  <teleport to="body">
    <div v-if="visible" class="AsomModal__Container">
      <div class="AsomModal__InnerContainer">
        <transition name="AsomModal__OverlayTransition">
          <div class="AsomModal__Overlay" aria-hidden="true" @click="handleOverlayClicked">
            <div class="AsomModal__InnerOverlay"></div>
          </div>
        </transition>
        <span class="AsomModal__AutoCenter" aria-hidden="true">&#8203;</span>
        <div :class="[ 'AsomModal', `AsomModal--size-${size}`]">
          <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
            <button v-if="dismissible" @click="close" type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span class="sr-only">Close</span>
              <!-- Heroicon name: outline/x -->
              <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div class="sm:flex sm:items-start">
            <div v-if="titleIcon === 'error'" class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <!-- Heroicon name: outline/exclamation -->
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
              <slot name="modal-title">
                <h3 class="text-lg leading-6 font-medium text-gray-900" v-if="title">
                  {{title}}
                </h3>
              </slot>
              <div class="mt-2 text-sm text-gray-500">
                <slot></slot>
              </div>
            </div>
          </div>
          <slot name="footer">
          </slot>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script>
/**
 * Refer to Tailwind design: https://tailwindui.com/components/application-ui/overlays/modals
 */
export default {
  name: 'AsomModal',
  props: {
    title: {
      type: String,
    },
    maskClosable: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'sm',
      validator: (val) => [ 'sm', 'md', 'lg', 'full' ].includes(val)
    },
    modelValue: {
      type: Boolean,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
    titleIcon: {
      type: String,
      default: '',
      validator: (val) => [ '', 'error' ].includes(val),
    },
    cancelTitle: {
      type: String,
      default: 'Cancel'
    },
    okTitle: {
      type: String,
      default: 'Ok'
    },
    cancelVariant: {
      type: String,
      default: 'secondary',
      validate: (value) => [
        'primary', 'secondary', 'success', 'error', 'warning', 'link', 'full'
      ].includes(value),
    },
    okVariant: {
      type: String,
      default: 'primary',
      validate: (value) => [
        'primary', 'secondary', 'success', 'error', 'warning', 'link', 'full'
      ].includes(value),
    },
    okOnly: {
      type: Boolean,
      default: false,
    }
  },
  emits: [ 'update:modelValue', 'cancel', 'ok' ],
  data() {
    return {
      visible: false,
    }
  },
  mounted() {
    this.visible = this.modelValue
  },
  watch: {
    modelValue(newVal) {
      this.visible = newVal
    }
  },
  methods: {
    close() {
      this.visible = false
      this.$emit('update:modelValue', false)
    },
    open() {
      this.visible = true
      this.$emit('update:modelValue', true)
    },
    toggle() {
      this.visible = !this.visible
      this.$emit('update:modelValue', this.visible)
    },
    handleOverlayClicked() {
      if (!this.maskClosable) return;
      this.close();
    },
    onCancel() {
      this.visible = false
      this.$emit('update:modelValue', false)
      this.$emit('cancel');
    },
    onOk() {
      this.visible = false;
      this.$emit('update:modelValue', false);
      this.$emit('ok');
    }
  }
}
</script>
<style scoped>
.AsomModal__Container {
  z-index: 100;
  @apply fixed inset-0 overflow-y-auto
}

.AsomModal__InnerContainer {
  @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0
}

.AsomModal__Overlay {
  @apply fixed inset-0 transition-opacity
}

.AsomModal__OverlayTransition-enter-from {
  @apply transform opacity-0 scale-95
}

.AsomModal__OverlayTransition-enter-active {
  @apply transition ease-out duration-100
}

.AsomModal__OverlayTransition-enter-to {
  @apply transform opacity-100 scale-100
}

.AsomModal__OverlayTransition-leave-from {
  @apply transform opacity-100 scale-100
}

.AsomModal__OverlayTransition-leave-active {
  @apply transition ease-in duration-75
}

.AsomModal__OverlayTransition-leave-to {
  @apply transform opacity-0 scale-95
}

.AsomModal__InnerOverlay {
  @apply absolute inset-0 bg-gray-500 opacity-75
}

.AsomModal__AutoCenter {
  @apply hidden sm:inline-block sm:align-middle sm:h-screen
}

.AsomModal {
  @apply inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:p-6
}

.AsomModal--size-sm {
  @apply sm:max-w-lg;
}

.AsomModal--size-md {
  @apply sm:max-w-3xl;
}

.AsomModal--size-lg {
  @apply sm:max-w-5xl;
}

.AsomModal--size-full {
  @apply sm:max-w-full
}

.AsomModal__Header {
  @apply rounded-t-lg flex justify-between content-start bg-white p-4 pb-2 border-b-2
}

.AsomModal__HeaderTitle {
  @apply text-lg leading-6 font-medium text-gray-900
}

.AsomModal__Body {
 @apply rounded-b-lg my-4 text-center sm:my-4 sm:ml-4 sm:mr-4 sm:text-left
}

.AsomModal__Footer {
  @apply bg-white
}
</style>