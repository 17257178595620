<template>
  <vue-multiselect :class="['AsomInputSelectYear']" track-by="label" label="label" v-bind="$attrs" :options="options"
    v-model="computedValue" :searchable="true" :disabled="disabled" ref="multiselect" :placeholder="placeholder">
  </vue-multiselect>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import inputStateMixin from "../inputStateMixin";

export default {
  name: "AsomInputSelectYear",
  components: {
    VueMultiselect,
  },
  inheritAttrs: false,
  mixins: [inputStateMixin],
  props: {
    modelValue: {
      type: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    objectModelValue: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Select option",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      options: [],
    }
  },
  created() {
    const toYear = new Date().getFullYear();
    for (let i = toYear; i >= toYear - 20; i--) {
      this.options.push({ label: i, value: i })
    }
  },
  computed: {
    computedValue: {
      get() {
        if (this.objectModelValue) return this.modelValue;
        if (this.options && this.options.find) {
          return this.options.find((option) => option.value == this.modelValue);
        }
        return null;
      },
      set(selectedOption) {
        if (this.objectModelValue) {
          this.$emit("update:modelValue", selectedOption);
          return;
        }
        this.$emit("update:modelValue", selectedOption && selectedOption.value);
      },
    },
  },
  methods: {
    clearDropdownOptions() {
      if (this.$refs.multiselect && this.$refs.multiselect.refreshOptions)
        this.$refs.multiselect.refreshOptions();
    },
  },
};
</script>
