import AsomAlert from "./alert/AsomAlert";
import AsomBadge from "./badge/AsomBadge";
import AsomBreadcrumb from "./breadcrumb/AsomBreadcrumb";
import AsomButton from "./button/AsomButton";
import AsomCard from "./card/AsomCard";
import AsomContainer from "./container/AsomContainer";
import AsomDropdown from "./dropdown/AsomDropdown";
import AsomFormField from "./form_display/AsomFormField";
import AsomIcon from "./icon/AsomIcon";
import AsomInputCheckbox from "./input/checkbox/AsomInputCheckbox";
import AsomInputCheckboxGroup from "./input/checkbox/AsomInputCheckboxGroup";
import AsomInputSelect from "./input/select/AsomInputSelect";
import AsomMultiSelect from "./input/multi_select/AsomMultiSelect";
import AsomInputDate from "./input/date/AsomInputDate";
import AsomInputDateRange from "./input/date/AsomInputDateRange";
import AsomInputDateTime from "./input/date/AsomInputDateTime";
import AsomInputSelectMonth from "./input/date/AsomInputSelectMonth";
import AsomInputSelectYear from "./input/date/AsomInputSelectYear";
import AsomInputTime from "./input/date/AsomInputTime";
import AsomInputRadioGroup from "./input/radio/AsomInputRadioGroup";
import AsomInputTextarea from "./input/textarea/AsomInputTextarea";
import AsomInputText from "./input/text/AsomInputText";
import AsomInputTextMax from "./input/text/AsomInputTextMax";
import AsomLink from "./link/AsomLink";
import AsomModal from "./modal/AsomModal";
import AsomPagination from "./pagination/AsomPagination";
import AsomClientTable from "./table/AsomClientTable";
import AsomServerTable from "./table/AsomServerTable";
import AsomTooltip from "./tooltip/AsomTooltip";
import AsomSlideMenu from "./slide_menu/AsomSlideMenu";
import AsomSlideItem from "./slide_menu/AsomSlideItem";
import AsomSignaturePad from "./signature_pad/AsomSignaturePad";
import AsomSignatureView from "./signature_pad/AsomSignatureView";
import AsomUpload from "./input/upload/AsomUpload";
import AsomUploadFileList from "./input/upload/_AsomUploadFileList";
import AsomBarCodeScanner from "./bar_code_scanner/AsomBarCodeScanner";
import AsomQRCodeScanner from "./qr_code_scanner/AsomQRCodeScanner";
import AsomDivider from "./divider/AsomDivider";
import AsomExportToExcel from "./excel/AsomExportToExcel";
import AsomHumanDiagramInput from "./input/human_diagram/AsomHumanDiagramInput.vue";
import AsomHumanDiagramView from "./input/human_diagram/AsomHumanDiagramView.vue";
import AsomInputTextEditor from "./input/text_editor/AsomInputTextEditor.vue";
import AsomInputMultiEmail from "./input/multi_email/AsomInputMultiEmail.vue";
import "vue-multiselect/dist/vue-multiselect.css";
import "./input/select/select.css";

export default {
  "asom-alert": AsomAlert,
  "asom-badge": AsomBadge,
  "asom-breadcrumb": AsomBreadcrumb,
  "asom-button": AsomButton,
  "asom-card": AsomCard,
  "asom-container": AsomContainer,
  "asom-dropdown": AsomDropdown,
  "asom-form-field": AsomFormField,
  "asom-icon": AsomIcon,
  "asom-input-checkbox": AsomInputCheckbox,
  "asom-input-checkbox-group": AsomInputCheckboxGroup,
  "asom-input-select": AsomInputSelect,
  "asom-multi-select": AsomMultiSelect,
  "asom-input-date": AsomInputDate,
  "asom-input-date-range": AsomInputDateRange,
  "asom-input-date-time": AsomInputDateTime,
  "asom-input-select-month": AsomInputSelectMonth,
  "asom-input-select-year": AsomInputSelectYear,
  "asom-input-time": AsomInputTime,
  "asom-input-radio-group": AsomInputRadioGroup,
  "asom-input-textarea": AsomInputTextarea,
  "asom-input-text": AsomInputText,
  "asom-input-text-max": AsomInputTextMax,
  "asom-link": AsomLink,
  "asom-modal": AsomModal,
  "asom-pagination": AsomPagination,
  "asom-client-table": AsomClientTable,
  "asom-server-table": AsomServerTable,
  "asom-tooltip": AsomTooltip,
  "asom-slide-menu": AsomSlideMenu,
  "asom-slide-item": AsomSlideItem,
  "asom-signature-pad": AsomSignaturePad,
  "asom-signature-view": AsomSignatureView,
  "asom-upload": AsomUpload,
  "asom-upload-file-list": AsomUploadFileList,
  "asom-bar-code-scanner": AsomBarCodeScanner,
  "asom-q-r-code-scanner": AsomQRCodeScanner,
  "asom-divider": AsomDivider,
  "asom-export-to-excel": AsomExportToExcel,
  "asom-human-diagram-input": AsomHumanDiagramInput,
  "asom-human-diagram-view": AsomHumanDiagramView,
  "asom-input-text-editor": AsomInputTextEditor,
  "asom-input-multi-email": AsomInputMultiEmail,
};
